import { SxProps, Theme } from "@mui/material"

export const useStyles = () => {
    const container: SxProps<Theme> = {
        maxWidth: "450px",
        maxHeight: "210px",
        width: "100%",
        borderRadius: "8px",
        padding: "8px", // Removed padding to match the example
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
    }

    const blocModal: SxProps<Theme> = {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "120px",
        padding: "16px",
        gap: "12px",
    }

    const titleWrapper: SxProps<Theme> = {
        display: "flex",
        width: "100%",
        minHeight: "28px",
        gap: "8px",
    }

    const closeButton: SxProps<Theme> = {
        position: "absolute",
        top: 16,
        right: 16,
        color: "primary.main",
        padding: "4px",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
    }

    const footer: SxProps<Theme> = {
        padding: 2,
        paddingTop: 2,
        justifyContent: "center",
    }

    const confirmButton: SxProps<Theme> = {
        borderRadius: "4px",
        padding: "12px",
        textTransform: "none",
        fontSize: "16px",
        backgroundColor: "#1976d2",
        "&:hover": {
            backgroundColor: "#1565c0",
        },
        height: "42px",
    }

    return {
        container,
        titleWrapper,
        closeButton,
        footer,
        confirmButton,
        blocModal,
    }
}
