import { CompanyApi, LocationsApi } from "@/api"
import { FetchLocationsList } from "@/api/locations"
import { CookiesService } from "@/services"
import amplitude from "@/services/amplitude"
import { USER_PROPERTIES } from "@/services/amplitude/types"
import ResellerStore from "@/store/reseller"
import UserSessionStore from "@/store/user-session"
import { checkIsAdminCompany, checkIsSuperAdmin, getPageAccess } from "@/utils/auth"
import { useCallback } from "react"

const useProfileRole = () => {
    const { role, profile, updateState } = UserSessionStore()

    const { resellerName } = ResellerStore()

    const isAdmin = useCallback(() => {
        if (profile?.uid) {
            return checkIsSuperAdmin(role)
        }
        return false
    }, [role, profile])

    const isAdminCompany = useCallback(() => {
        if (profile?.uid) {
            return checkIsAdminCompany(role)
        }
        return false
    }, [profile, role])

    const checkingPageAccess = useCallback(async () => {
        if (!profile?.uid) return

        const promises = [
            CompanyApi.fetchListsCompanies({
                fields: "quicksite,uid,name",
                user_uid: profile.uid,
            }),
            LocationsApi.fetchLocationsWithFields({
                fields: ["id", "name"],
                user_uid: profile.uid,
            }),
        ]

        const [companies, locations] = await Promise.all(promises)

        const company_id = companies?.data?.map((data: any) => data.uid)?.join(",") || ""
        const company_name = companies?.data?.map((data: any) => data.name)?.join(",") || ""
        const business_id = locations?.data?.map((data: any) => data.id)?.join(",") || ""
        const business_name = locations?.data?.map((data: any) => data.name)?.join(",") || ""

        const userProperties: USER_PROPERTIES = {
            user_id: profile.uid,
            business_id,
            business_name,
            company_id,
            company_name,
            reseller_id: CookiesService.get("resellerUid") || "",
            reseller_name: resellerName,
            user_type: isAdmin() ? "dilypseAdmin" : isAdminCompany() ? "resellerAdmin" : "client",
        }

        amplitude.setUserProperties(userProperties)

        const isSingleLocation = ((locations?.data || []) as Array<any>)?.length === 1
        const page = getPageAccess({ companies: companies?.data || [] })
        updateState({
            page,
            isSingleLocation,
            singleLocationId: isSingleLocation ? (locations?.data as FetchLocationsList)[0]?.id : "",
        })
    }, [profile?.uid, resellerName, isAdminCompany, isAdmin, updateState])

    return {
        isAdmin,
        isAdminCompany,
        checkingPageAccess,
    }
}

export default useProfileRole
