import { AdvancedCard } from "@/components/widget/advanced-card"
import { Box, Button, Typography, styled } from "@mui/material"
import { LocationLogo } from "@/components/features/qr-code-link/location-logo"
import CopiableLink from "@/components/base/copiable-link"
import StatusChip from "../status-chip"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { LocationType } from "@/types/businesses"
import ViewQuicksite from "@/components/widget/web-page/view-quicksite"

type LocationCardProps = {
    location: any
    onClick?: () => void
    isMobile?: boolean
    showWarning?: boolean
}

const FooterContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "end",
    gap: "24px",
})

const LocationCardFooter: React.FC<{
    quicksite?: { url: string; status: "IN_PROGRESS" | "PUBLISHED" | "SUSPENDED" | "FAILED" }
    buttonLabel?: string
    locationId?: string
}> = ({ quicksite, buttonLabel, locationId }) => {
    const navigate = useNavigate()
    return !quicksite ? (
        <Button variant="contained" onClick={() => navigate("/web-page/" + locationId)}>
            {buttonLabel}
        </Button>
    ) : (
        <FooterContainer>
            {quicksite.status !== "FAILED" && quicksite.status !== "IN_PROGRESS" && (
                <ViewQuicksite link={quicksite.url} />
            )}
            {/* [MVP] Ne pas afficher le lien vers le quicksite lorsque le statut est "En cours" ou "En échec" */}
            {/* https://ubiweb.atlassian.net/jira/software/c/projects/DLP/issues/DLP-4636 */}
            {quicksite.status !== "FAILED" && quicksite.status !== "IN_PROGRESS" && (
                <CopiableLink link={quicksite.url} />
            )}
        </FooterContainer>
    )
}

export const LocationCard: React.FC<LocationCardProps> = ({
    onClick,
    location,
}: {
    onClick: () => void
    location: LocationType
}) => {
    const { t } = useTranslation()
    return (
        <AdvancedCard
            footer={
                <LocationCardFooter
                    quicksite={location.quicksiteInfo}
                    buttonLabel={t("WEB_PAGE.CARD.CREATE_YOUR_PAGE")}
                    locationId={location.id}
                />
            }
            footerSx={{
                display: "flex",
                justifyContent: "end",
                padding: "16px",
                gap: "12px",
                backgroundColor: "#EBEEF5",
                height: "100%",
            }}
            sx={{
                height: "auto",
                width: "100%",
                cursor: "pointer",
                gap: 0,
                "&:hover": { boxShadow: 4 },
                "@media (max-width: 767px)": { gap: 0, padding: 0 },
            }}
            contentSx={{
                display: "flex",
                justifyContent: "space-between",
            }}
            onClick={onClick}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, height: "80px" }}>
                <LocationLogo src={location.logo} alt={location.name} width={72} height={72} />
                <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold", mb: 0.5 }}>
                        {location.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {location.address}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <StatusChip status={location?.quicksiteInfo?.status} />
            </Box>
        </AdvancedCard>
    )
}
