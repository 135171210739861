import React from "react"
import { Dialog, DialogActions, IconButton, Typography, Button, SxProps, Divider, Stack, Box } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useStyles } from "./style"

export interface CustomModalProps {
    open: boolean
    onClose: () => void
    title?: string
    description?: string | React.ReactNode
    confirmButtonText?: string
    onConfirm?: () => void
    showCloseIcon?: boolean

    // Style customization props
    containerSx?: SxProps
    titleSx?: SxProps
    descriptionSx?: SxProps
    footerSx?: SxProps
    confirmButtonSx?: SxProps
    dividerSx?: SxProps
}

/**
 * Un composant de modal personnalisable utilisant Material-UI.
 *
 * @param {object} props - Les propriétés du composant.
 * @param {boolean} props.open - Indique si la modal est ouverte ou fermée.
 * @param {() => void} props.onClose - Fonction appelée lors de la fermeture de la modal.
 * @param {string} [props.title] - Titre de la modal (optionnel).
 * @param {string | React.ReactNode} [props.description] - Contenu de la description.
 * @param {string} [props.confirmButtonText] - Texte du bouton de confirmation.
 * @param {() => void} [props.onConfirm] - Fonction appelée lors du clic sur le bouton de confirmation.
 * @param {boolean} [props.showCloseIcon=true] - Affiche ou non l'icône de fermeture.
 * @param {SxProps} [props.containerSx] - Styles personnalisés pour le conteneur de la modal.
 * @param {SxProps} [props.titleSx] - Styles personnalisés pour le titre.
 * @param {SxProps} [props.descriptionSx] - Styles personnalisés pour la description.
 * @param {SxProps} [props.footerSx] - Styles personnalisés pour le pied de la modal.
 * @param {SxProps} [props.confirmButtonSx] - Styles personnalisés pour le bouton de confirmation.
 * @param {SxProps} [props.dividerSx] - Styles personnalisés pour le séparateur.
 * @returns {JSX.Element} Le composant `CustomModal`.
 */
const CustomModal: React.FC<CustomModalProps> = ({
    open,
    onClose,
    title = "",
    description = "",
    confirmButtonText = "",
    onConfirm,
    showCloseIcon = true,

    // Style props
    containerSx,
    titleSx,
    descriptionSx,
    footerSx,
    confirmButtonSx,
    dividerSx,
}) => {
    const styles = useStyles()

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm()
        }
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={(theme) => ({
                "& .MuiDialog-paper": {
                    ...styles.container,
                    ...(containerSx && typeof containerSx === "function" ? containerSx(theme) : containerSx),
                },
            })}
        >
            <Stack className="bloc-modal">
                <Stack sx={styles.blocModal}>
                    {title && (
                        <Box className="title-wrapper" sx={{ ...styles.titleWrapper, ...titleSx }}>
                            <Typography variant="h2">{title}</Typography>
                            {showCloseIcon && (
                                <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Box>
                    )}

                    <Stack sx={{ ...(descriptionSx || {}) }}>
                        {typeof description === "string" ? (
                            <Typography variant="body1" color={"text.secondary"}>
                                {description}
                            </Typography>
                        ) : (
                            description
                        )}
                    </Stack>
                </Stack>

                <Divider sx={{ ...(dividerSx || {}) }} />

                <DialogActions sx={{ ...styles.footer, ...(footerSx || {}) }}>
                    <Button
                        onClick={handleConfirm}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ ...confirmButtonSx }}
                    >
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </Stack>
        </Dialog>
    )
}

export default CustomModal
